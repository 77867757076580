<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="6"
            md="6"
            class="
              d-flex
              align-items-center
              justify-content-start
              mb-1 mb-md-0
            "
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="6"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div>
          </b-col>
        </b-row>


        <b-row class="my-10" v-if="rowSelect">
      
          <!-- Per Page -->
          <b-col cols="4">
            <b-button
              variant="outline-primary"
              @click="$router.push(`/update-country-pages/${itemId}`)"
            >
            <feather-icon icon="PlusIcon" />
              <span class="align-middle ">update</span>
            </b-button>
          </b-col>
          <b-col cols="4" class="mt-2">
            <b-button
              variant="outline-primary"
              @click="$router.push(`/add-faq/${itemId}`)"
            >
            <feather-icon icon="PlusIcon" />
            <span class="align-middle ">add Faq</span>
            </b-button>
          </b-col>
          <b-col cols="4" class="mt-2">
            <b-button
              variant="outline-primary"
              @click="$router.push(`/add-schedule-country-pages/${itemId}`)"
            >
            <feather-icon icon="PlusIcon" />
            <span class="align-middle ">add Schedule Country Page</span>
            </b-button>
          </b-col>
          <b-col cols="4">
            <b-button
              variant="outline-primary"
              @click="$router.push(`/list-schedule-country-pages/${itemId}`)"
            >
            <feather-icon icon="PlusIcon" />
            <span class="align-middle ">list Schedule Country Page</span>
            </b-button>
          </b-col>
          <b-col cols="4" class="mt-2">
            <b-button
              variant="outline-primary"
              @click="$router.push(`/add-long-schedule-country-pages/${itemId}`)"
            >
            <feather-icon icon="PlusIcon" />
            <span class="align-middle ">add Diploma Schedule Country Page</span>
            </b-button>
          </b-col>
          <b-col cols="4" class="mt-2">
            <b-button
              variant="outline-primary"
              @click="$router.push(`/list-long-schedule-country-pages/${itemId}`)"
            >
            <feather-icon icon="PlusIcon" />
            <span class="align-middle ">list Diploma Schedule Country Page</span>
            </b-button>
          </b-col>
            <b-col cols="4" class="mt-2">
            <b-button
              variant="outline-primary"
              @click="$router.push(`/add-full-discount-country-page/${itemId}`)"
            >
            <feather-icon icon="PlusIcon" />
            <span class="align-middle ">Full Discount Country Page</span>
            </b-button>
          </b-col>
          <!-- <b-col cols="2">
            <b-button
              variant="outline-danger"
              @click="DeleteItem(itemId)"
            >
            <feather-icon icon="TrashIcon" />
            <span class="align-middle ml-50">Delete</span>
            </b-button>
          </b-col> -->
       
        </b-row>
        <b-row v-else>
          <label> Please select row to show actions</label>
        </b-row>
      </div>

      <b-table
        ref="refCourseListTable"
        class="position-relative"
        
        responsive
        :fields="tableColumns"
        primary-key="id"
        :items="accreditationsList"
       
        select-mode="single"
        :sort-by.sync="sort"
        selectable
        show-empty
        empty-text="No matching records found"
        @row-selected="onRowSelected"
      >
        <!-- Column: Status -->

        <!-- Column: Actions -->

        <!-- Column: Actions -->

         <template #cell(actions)="data">
        <a role="button" @click="DeleteItem(data.item.id)">

          <feather-icon icon="TrashIcon" class="mr-50" />
        </a>
        </template> 
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted">
              Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalaccreditations"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BPagination,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {
  ref, watch, computed, reactive,
} from '@vue/composition-api'
import Vue from 'vue'
import store from '@/store'

// import useCousresList from './useCousresList'
// import userStoreModule from '../userStoreModule'

export default {
  components: {
    // useCousresList,
    BDropdown,
  BDropdownItem,
    BCard,
    BRow,
    BCol,
    BTable,
    BPagination,

    vSelect,
  },
  setup() {
    const accreditationsList = ref([])
  const perPageOptions = [100, 200, 400, 600]
    const perPage = ref(50)
    const totalaccreditations = ref(0)
    const currentPage = ref(1)
    const refCourseListTable = ref('')
    const sort = ref('')
    const SortArray = []
    const searchQuery = ref('')
    const rowSelect=ref(false)

    const tableColumns = [
      { key: 'id', label: 'Id', sortable: true },
      { label: ' title', key: 'title', sortable: true },
      { key: 'slug', key: 'slug', sortable: true },

      { key: 'actions' },
    ]
  
    const dataMeta = computed(() => {
      const localItemsCount = refCourseListTable.value
        ? refCourseListTable.value.localItems.length
        : 0
      return {
        from:
          perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalaccreditations.value,
      }
    })
    const meta = reactive({})

    const getaccreditations = () => {
      let data = null

      if (!SortArray.includes(sort.value) && sort.value != '') {
        SortArray.push(sort.value)
        data = SortArray.toString().replace('[', '')
      }

      store
        .dispatch('setting/AllCountryPages', {
          // "filter[search]": searchQuery.value,
          'filter[search]': searchQuery.value,
          sort: data,
          per_page: perPage.value,
          page: currentPage.value,
        })
        .then(response => {
          console.log(response)
          accreditationsList.value = response.data.data
          totalaccreditations.value = response.data.data.total
        })
    }


    watch([currentPage, perPage, sort,searchQuery], () => {
      getaccreditations()
    })
    getaccreditations()
    const itemId=ref('')
    const onRowSelected = items => {
      console.log(items[0])
      itemId.value = items[0].id
      rowSelect.value=true
    }
    const DeleteItem = id => {
      Vue.swal({
        icon: 'warning',
        title: 'Are You Sure?',
        confirmButtonText: 'YES',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      }).then(result => {
        if (result.value) {
          store
            .dispatch('setting/DeleteCountryPage', id)
            .then(response => {
              getaccreditations()
              Vue.swal({
                title: ' Deleted ',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
        }
      })
    }

    return {
      searchQuery,
      tableColumns,
      accreditationsList,
      DeleteItem,
      meta,
      getaccreditations,
      perPageOptions,
      totalaccreditations,
      currentPage,
      perPage,
      dataMeta,
      refCourseListTable,
      SortArray,
      sort,
      itemId,
      onRowSelected,
      rowSelect
    }
  },
}
</script>

<style>
.per-page-selector {
  width: 90px;
}

.my-10{
  margin-top: 25px;
}
.dropdown-menu.show {
    height: 100px !important;
    overflow: auto !important;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
